/* src/theme/agentMonitorTabs.css */
.agent-monitor-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .agent-monitor-tabs-list {
    display: flex;
    position: relative;
    gap: 0;
    border-bottom: 1px solid var(--mantine-color-gray-2);
  }
  
  .agent-monitor-tab {
    flex: 1 1 0;
    max-width: 12.5rem;
    padding: 1rem 1.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--mantine-color-dark-7);
    background-color: transparent;
    border: none;
    position: relative;
    transition: all 200ms ease;
  }
  
  .agent-monitor-tab:hover {
    color: var(--mantine-color-dark-9);
    font-weight: 600;
  }
  
  .agent-monitor-tab[data-active] {
    color: var(--mantine-color-dark-9);
    font-weight: 500;
  }
  
  .agent-monitor-tab[data-active]::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--mantine-color-dark-9);
  }
  
  .agent-monitor-panel {
    padding: 2rem 0;
  }