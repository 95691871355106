/* Base button styles */
.button-primary {
  background-color: var(--mantine-color-blue-10);
  color: var(--mantine-color-white);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: var(--mantine-radius-md);
  border: none;
  transition: all 0.2s ease;
}

.button-primary:hover {
  background-color: var(--mantine-color-blue-8);
}

.button-secondary {
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-navy-2);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: var(--mantine-radius-md);
  border: 1px solid var(--mantine-color-navy-2);
  transition: all 0.2s ease;
}

.button-secondary:hover {
  background-color: var(--mantine-color-gray-0);
  border-color: var(--mantine-color-navy-2);
  color: var(--mantine-color-navy-2);
}

/* New subtle variant */
.button-subtle {
  background-color: var(--mantine-color-gray-0);
  color: var(--mantine-color-navy-3);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: var(--mantine-radius-md);
  border: none;
  transition: all 0.2s ease;
}

.button-subtle:hover {
  background-color: var(--mantine-color-gray-1);
  color: var(--mantine-color-navy-2);
}

/* New light variant */
.button-light {
  background-color: var(--mantine-color-blue-0);
  color: var(--mantine-color-blue-6);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: var(--mantine-radius-md);
  border: none;
  transition: all 0.2s ease;
}

.button-light:hover {
  background-color: var(--mantine-color-blue-1);
  color: var(--mantine-color-blue-7);
}

/* Disabled states for all variants */
.button-primary:disabled,
.button-secondary:disabled,
.button-subtle:disabled,
.button-light:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Focus states for all variants */
.button-primary:focus,
.button-secondary:focus,
.button-subtle:focus,
.button-light:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--mantine-color-blue-2);
}

/* Active states for all variants */
.button-primary:active {
  background-color: var(--mantine-color-blue-9);
}

.button-secondary:active {
  background-color: var(--mantine-color-gray-1);
}

.button-subtle:active {
  background-color: var(--mantine-color-gray-2);
}

.button-light:active {
  background-color: var(--mantine-color-blue-2);
}