.nav-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);  /* Black with some transparency */
    background-color: transparent;
    width: 100%;
    text-decoration: none;
    transition: all 200ms ease;
    font-size: 1rem;
    margin: 0.25rem 0;
  }
  
  .nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .nav-item.active {
    background-color: white;
    color: var(--mantine-color-dark-9);
    font-weight: 600;
  }
  
  .nav-item svg {
    margin-right: 0.75rem;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .nav-item.active svg {
    color: var(--mantine-color-dark-9);
  }
  
  /* Admin menu styling */
  .admin-menu {
    width: 100%;
    margin: 0.25rem 0;
  }
  
  .admin-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    background-color: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: all 200ms ease;
  }
  
  .admin-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .admin-button svg {
    margin-right: 0.75rem;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  /* Center navigation items container */
  .nav-items-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.25rem;
    margin-top: 1rem;
  }