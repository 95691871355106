/* FileManagement.module.css */

.container {
    padding: 0;
    background-color: var(--mantine-color-white);
    border-radius: var(--mantine-radius-md);
  }
  
  .sectionTitle {
    color: var(--mantine-color-blue-11);
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: var(--mantine-spacing-lg);
  }
  
  .uploadSection {
    padding: var(--mantine-spacing-md);
    background-color: var(--mantine-color-white);
    border-radius: var(--mantine-radius-md);
    border: 1px solid var(--mantine-color-gray-3);
  }
  
  .dropzone {
    border: 2px dashed var(--mantine-color-blue-4);
    background-color: var(--mantine-color-blue-0);
    transition: all 200ms ease;
  }
  
  .dropzone[data-accept] {
    border-color: var(--mantine-color-teal-6);
    background-color: var(--mantine-color-teal-0);
  }
  
  .dropzone[data-reject] {
    border-color: var(--mantine-color-red-6);
    background-color: var(--mantine-color-red-0);
  }
  
  .dropzoneContent {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--mantine-spacing-xl);
    pointer-events: none;
  }
  
  .fileList {
    margin-top: var(--mantine-spacing-lg);
  }
  
  .fileItem {
    padding: var(--mantine-spacing-xs);
    border-radius: var(--mantine-radius-sm);
    transition: background-color 150ms ease;
  }
  
  .fileItem:hover {
    background-color: var(--mantine-color-gray-0);
  }
  
  .mainFile {
    background-color: var(--mantine-color-blue-0);
  }
  
  .mainFile:hover {
    background-color: var(--mantine-color-blue-1);
  }
  
  .actionButtons {
    gap: var(--mantine-spacing-xs);
  }
  
  .editorContainer {
    border-radius: var(--mantine-radius-sm);
    overflow: hidden;
  }
  
  .modalActions {
    margin-top: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-sm);
  }
  
  .hiddenInput {
    display: none;
  }
  
  .fileIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }