.progressContainer {
  position: relative;
  padding: 3.75rem 0;  /* 60px -> 3.75rem */
  width: 70%;       
  min-width: 18.75rem; /* 300px -> 18.75rem */
  margin: 2rem auto; 
}

.progressLine {
  width: 100%;
  height: 0.125rem; /* 2px -> 0.125rem */
  background-color: #E9ECEF;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1.5625rem;  /* 25px -> 1.5625rem */
}

.progressFill {
  position: absolute;
  height: 100%;
  background-color: var(--mantine-color-blue-6);
  left: 0;
}

.dashedLine {
  position: absolute;
  height: 100%;
  right: 0;
  border-top: 0.125rem dashed #E9ECEF; /* 2px -> 0.125rem */
}

.dot {
  width: 0.75rem;   /* 12px -> 0.75rem */
  height: 0.75rem;  /* 12px -> 0.75rem */
  border-radius: 50%;
  background-color: #fff;
  border: 0.125rem solid #E9ECEF; /* 2px -> 0.125rem */
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.startDot {
  composes: dot;
  left: 0;
  background-color: var(--mantine-color-blue-6);
  border-color: var(--mantine-color-blue-6);
}

.nowDot {
  composes: dot;
  background-color: #fff;
  border-color: var(--mantine-color-blue-6);
}

.endDot {
  composes: dot;
  right: 0;
  transform: translate(50%, -50%);
}

/* Labels */
.label {
  position: absolute;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-navy-3);
  white-space: nowrap;
}

.date {
  position: absolute;
  font-size: var(--mantine-font-size-xs);
  color: var(--mantine-color-navy-4);
  white-space: nowrap;
}

/* Start point */
.labelStart {
  composes: label;
  left: 0;
  top: 1.5625rem;  /* 25px -> 1.5625rem */
  transform: translateX(0);
}

.dateStart {
  composes: date;
  left: 0;
  top: 2.8125rem;  /* 45px -> 2.8125rem */
  transform: translateX(0);
}

/* Now point */
.labelNow {
  composes: label;
  top: 6.875rem;  /* 110px -> 6.875rem */
  transform: translateX(-50%);
}

.dateNow {
  composes: date;
  top: 8.125rem;  /* 130px -> 8.125rem */
  transform: translateX(-50%);
}

/* End point */
.labelEnd {
  composes: label;
  right: 0;
  top: 1.5625rem;  /* 25px -> 1.5625rem */
  transform: translateX(100%);
}

.dateEnd {
  composes: date;
  right: 0;
  top: 2.8125rem;  /* 45px -> 2.8125rem */
  transform: translateX(100%);
}

/* Edge case styles */
.nowNearStart {
  transform: translateX(-20%);
}

.nowNearEnd {
  transform: translateX(-80%);
}