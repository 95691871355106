/* src/theme/competitionTabs.css */
.competition-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.competition-tabs-list {
  display: flex;
  position: relative;
  gap: 0;
}

.competition-tab {
  flex: 1 1 0;
  max-width: 12.5rem;
  padding: 1rem 1.5rem;
  font-weight: 500;
  font-size: 1.125rem;
  color: var(--mantine-color-dark-7);
  background-color: transparent;
  border: none;
  position: relative;
  transition: all 200ms ease;
}

.competition-tab:hover {
  color: var(--mantine-color-dark-9);
  font-weight: 600;
}

.competition-tab[data-active] {
  color: var(--mantine-color-dark-9);
  font-weight: 500;
}

.competition-tab[data-active]::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--mantine-color-dark-9);
}

.mantine-Tabs-root[data-orientation="horizontal"] .mantine-Tabs-list::before,
.mantine-Tabs-root[data-orientation="horizontal"] .mantine-Tabs-list::after,
.mantine-Tabs-indicator {
  display: none !important;
}

.competition-panel {
  padding: 2rem 0;
}